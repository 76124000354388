import React, { ReactElement } from "react";

export default function DoubleArrowIcon({
    className,
}: {
    className?: string | undefined;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="18"
            viewBox="0 0 21 18"
            className={className}
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#FFF" fillRule="nonzero">
                    <path d="M2.51945229 0L-4.23661106e-13 2.48571429 6.66011737 9 -4.23661106e-13 15.5142857 2.51945229 18 11.7209302 9z"></path>
                    <path d="M11.7985221 0L9.27906977 2.48571429 15.9391871 9 9.27906977 15.5142857 11.7985221 18 21 9z"></path>
                </g>
            </g>
        </svg>
    );
}
