import CheckIcon from "@components/svg/checkIcon";
import { ListingItem } from "@lib/shared/types";
import React from "react";
import styles from "./offer.module.scss";
import DoubleArrowIcon from "@components/svg/doubleArrowIcon";
import Image from "next/image";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { AffiliateOfferZone } from "@lib/shared/config";
interface Props {
    id: number;
    offer: ListingItem;
    comparison: boolean;
    zone: AffiliateOfferZone;
}

const Offer: React.FC<Props> = ({ id, offer, comparison, zone }) => {
    const { logo, features, attributes, price, currency = "$" } = offer;

    const wantedAttributes = [
        "free plan",
        "storage space",
        "free domain",
        "ecommerce plan",
    ];

    return (
        <div
            className={`flex relative p-3 border-b-2 lg:border-2 flex-col justify-between lg:my-6 lg:h-72 ${
                styles["listing-item"]
            } ${comparison ? styles.shift : ""}`}
        >
            <AffiliateOfferUrl
                zone={zone}
                offer={offer}
                target="_blank"
                className="absolute w-full h-full top-0 left-0 z-10"
            />

            <div
                className={`flex absolute top-3 left-3 w-8 h-8 lg:w-12 lg:h-12 rounded ${styles.gray} items-center font-bold text-base lg:text-2xl justify-center`}
            >
                <p>{id + 1}</p>
            </div>
            <div
                className={`flex flex-row justify-between items-center h-full lg:mb-5`}
            >
                <div className="ml-4 xs:ml-10 sm:ml-16 md:w-28 lg:ml-32">
                    {logo?.fullPath && (
                        <Image
                            src={`${logo.fullPath}`}
                            width={120}
                            height={120}
                            alt={offer.title}
                        />
                    )}
                </div>
                <div className="hidden lg:block w-5/12">
                    <ul className="flex h-24 flex-wrap text-sm content-center">
                        {features?.map((item, idx) => {
                            return (
                                <li
                                    key={idx}
                                    className="flex items-center mb-2 w-6/12 h-3.5"
                                >
                                    <CheckIcon className="w-3 h-3 mr-2" />
                                    <p>{item}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="flex justify-center flex-col text-base ml-2">
                    <div className="flex items-center justify-start mr-1">
                        <p className={` ml-2 font-bold lg:text-2xl`}>
                            {currency}
                            {price}
                            <span className="text-xs font-light">/month</span>
                        </p>
                    </div>
                    <button
                        className={`${styles.button} h-10 flex items-center justify-center lg:text-xl py-2 w-32 lg:w-40 rounded-md font-bold`}
                    >
                        <span>Visit Site</span>
                        <DoubleArrowIcon className="w-3 md:w-4 ml-2 lg:w-5" />
                    </button>
                </div>
            </div>
            {Object.keys(attributes).length > 0 && (
                <div className={`text-sm mt-3 lg:mt-0 hidden lg:flex`}>
                    <ul
                        className={`${styles.yellow} justify-evenly md:justify-around text-xs p-3 flex rounded-md w-full font-poppins`}
                    >
                        {Object.keys(attributes).map((attributeKey, idx) => {
                            return (
                                wantedAttributes.includes(
                                    attributeKey?.toLocaleLowerCase(),
                                ) && (
                                    <li key={idx}>
                                        <div className="flex flex-row">
                                            <p>
                                                {attributeKey}{" "}
                                                <span className="font-bold">
                                                    {attributes[attributeKey]
                                                        ?.toLowerCase()
                                                        ?.includes("true")
                                                        ? "Available"
                                                        : attributes[
                                                              attributeKey
                                                          ]}
                                                </span>
                                            </p>
                                        </div>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Offer;
