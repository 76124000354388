import { affiliateOfferZones } from "@lib/shared/config";
import React from "react";
import { ListingItem } from "src/lib/shared/types";
import Offer from "../offer";
import styles from "./mainListing.module.scss";
interface Props {
    listing: ListingItem[] | undefined;
    comparison: boolean;
}

const MainListing: React.FC<Props> = ({ listing, comparison }) => {
    return (
        <div
            className={` pb-10 lg:pt-10 ${styles.listing} ${
                comparison ? styles.comparison : ""
            }`}
        >
            {listing &&
                listing.map((item, idx) => {
                    return (
                        <Offer
                            zone={
                                affiliateOfferZones[
                                    comparison ? "COMPARISON_PAGE" : "HOME_PAGE"
                                ]
                            }
                            id={idx}
                            key={idx}
                            offer={item}
                            comparison={comparison}
                        />
                    );
                })}
        </div>
    );
};
export default MainListing;
